/* body {
  width: 1060px;
  margin: 50px auto;
} */

.histogramRef {
  width: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px !important;
}

.histogramRef table {
  order: 1;
}

.histogramRef .pieChart {
  order: 2;
}

.histogramRef .barChart {
  order: 3;
}

.histogramRef text {
  font-size: 12px !important;
}
.histogramRef text.xaxisText {
  font-size: 14px !important;
}

.histogramRef .raceagetext {
  font-size: 8px !important;
}

.histogramRef .tick text {
  font-size: 10px !important;
  font-weight: 300 !important;
}

text {
  font-weight: 300;
}

path {
  stroke: #fff;
}

path:hover {
  opacity: 0.8;
}

rect:hover {
  opacity: 0.8;
}

.axis {
  font: 10px sans-serif;
}

.legend tr {
  border-bottom: 1px solid grey;
}

.legend tr:first-child {
  border-top: 1px solid grey;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

.pieChart {
  overflow: visible; /* !important ****/
}

.legend {
  width: 250px;
  height: fit-content;
  position: relative;
  font-size: 12px;
  line-height: 12px;
  color: #2a363d;
  margin-bottom: 10px;
  display: inline-block;
  border-collapse: collapse;
  border-spacing: 0px;
  /* background-color: transparent; */
  /* box-shadow: 0px 1px 1px 2px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
  border: 1px solid rgba(128, 128, 128, 0.4);
  /* overflow: visible; */
}

.legend tr:first-child {
  border-top: none;
}

.legend tr {
  border-bottom: none;
}

.legend td {
  font-size: 11px !important;
  font-weight: 300 !important;
  padding: 4px 5px;
  vertical-align: middle;
}
.legendTotal,
.legendPerc {
  align-self: right;
  width: 50px;
}

.MuiDialogContent-root .legend {
  margin-top: 40px;
  float: right;
  margin-right: 55px;
}

.histogramRefReport .barChart {
  display: block;
}

.histogramRefReport .legend {
  display: block;
}

.histogramRefReport .pieChart {
  display: block;
}
